import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Grid, IconButton, Link, Skeleton, Stack, Typography } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import LicenseInput from "./licenseInput";
import axios from "axios";
import TimeSelect from "./timeSelect";
import { cancelParking, generateNewParkingRequest, getParkingConstants, ParkingPermit } from "../shared";
import { green, red } from '@mui/material/colors';

const Profile = () => {
    const { user, isAuthenticated, isLoading, getAccessTokenWithPopup, getIdTokenClaims } = useAuth0();

    const [license, setLicense] = useState('');
    const updateLicense = (newValue: string) => {
        setLicense(newValue.toUpperCase());
    }

    const [isSaving, setIsSaving] = useState(false);

    const [location, setLocation] = useState('');
    const [policy, setPolicy] = useState('');
    const [passcode, setPasscode] = useState('');
    const [permit, setPermit] = useState<ParkingPermit | null>(null);
    const updatePermit = (newPermit: ParkingPermit) => {
        setPermit(newPermit)
        savePermitToAuth0(newPermit.id)
    }

    const [time, setTime] = useState(1);


    const saveLicense = async (plate: string) => {
        setIsSaving(true)
        const token = await getAccessTokenWithPopup({ audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`, scope: 'update:current_user_metadata' });
        const payload = {
            user_metadata: { license: license }
        }
        const res = await axios.patch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user?.sub}`, payload, { headers: { 'Authorization': `Bearer ${token}` } })
        setIsSaving(false)
        if (res.status === 200) {
        }
    }


    const getPermit = async (permitId: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            axios.get(`https://api.parkingboss.com/v1/permits/${permitId}`).then((res) => {
                const data = res.data;
                const foundPermit = data.permits?.items[permitId] || {};
                const validObj = foundPermit.valid || {};
                const expired = Boolean(validObj.expired);
                const from = new Date(Date.parse(validObj.from))
                const to = new Date(Date.parse(validObj.to))
                const url = String(foundPermit?.url)
                setPermit({
                    id: permitId,
                    from: from,
                    to: to,
                    expired: expired,
                    url: url
                })
                resolve()
            })
        })
    }

    const savePermitToAuth0 = async (permitId: string) => {
        const token = await getAccessTokenWithPopup({ audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`, scope: 'update:current_user_metadata' });
        const payload = {
            user_metadata: {
                permit: permitId
            }
        }
        const res = await axios.patch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user?.sub}`, payload, { headers: { 'Authorization': `Bearer ${token}` } })
    }

    const [isReserving, updateIsReserving] = useState(false);

    const reserveParking = async () => {
        updateIsReserving(true);
        try {
            const res = await generateNewParkingRequest(location, policy, license, passcode, time, user?.email || '', user?.phone_number || '')
            const id = String(res?.permits?.item);
            const permitObj = res?.permits?.items[id] || {};
            const newPermit: ParkingPermit = {
                id: id,
                from: new Date(Date.parse(permitObj?.valid?.from)),
                to: new Date(Date.parse(permitObj?.valid?.to)),
                url: String(permitObj?.url),
                expired: Boolean(permitObj?.valid?.expired)
            }
            updatePermit(newPermit)
        } catch (error) {
            console.log('error encountered while creating permit:')
            console.error(error)
        }
        updateIsReserving(false);
    }

    const cancelPermit = async () => {
        const phone = user?.phone_number || '';
        const email = user?.email || '';
        console.log(phone)
        console.log(email)
        if (permit && (phone || email)) {
            cancelParking(permit.id, phone !== '' ? phone : email).then((res) => {
                getPermit(permit.id)
            })
        }
    }

    useEffect(() => {
        getParkingConstants().then((constants) => {
            setLocation(constants.location)
            setPolicy(constants.policy)
        })
    }, [])

    useEffect(() => {
        getIdTokenClaims().then((token) => {
            if (token) {
                if (token['https://license'] && token['https://passcode']) {
                    updateLicense(token['https://license'])
                    setPasscode(token['https://passcode'])
                }
                if (token['https://permit']) {
                    const permitId: string = token['https://permit']
                    getPermit(permitId)
                }
            }
        })
    }, [getIdTokenClaims, isLoading])

    if (isLoading) {
        return (
            <Grid container justifyContent={"center"}>
                <Grid item>
                    <Box sx={{ width: 375 }}>
                        <Stack spacing={2}>
                            <Skeleton variant='rounded' height={40} />
                            <Skeleton variant='rounded' height={20} />
                            <Skeleton variant='rounded' height={60} />
                            <Skeleton variant='rounded' height={30} />
                            <Skeleton variant='rounded' height={40} />
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    if (!isAuthenticated) {
        return <div></div>;
    }

    return (
        <Stack spacing={2}>
            <Typography variant="subtitle1">Hello, {user?.given_name}!</Typography>
            <Box>
                <LicenseInput value={license} updateValue={updateLicense} saveLicense={saveLicense} isSaving={isSaving} />
            </Box>
            <Box>
                <TimeSelect reserve={reserveParking} isSubmitting={isReserving} disabled={permit ? !permit.expired : false} time={time} setTime={setTime} />
                {permit && (
                    <div>
                        <br />
                        <Typography color={permit.expired ? red['A700'] : green['A700']} variant={"h6"}>Permit {permit.expired ? 'Expired' : 'Active'} <IconButton onClick={() => getPermit(permit.id)}><RefreshIcon /></IconButton> </Typography>
                        <Typography>Valid From: {permit.from.toLocaleString()}</Typography>
                        <Typography>{permit.expired ? 'Expired At' : 'Valid To'}: {permit.to.toLocaleString()}</Typography>
                        <Button color={'error'} disabled={permit.expired} onClick={() => cancelPermit()}>Cancel Parking</Button><br/>
                        <Link href={permit.url}>your permit</Link>
                    </div>
                )}
            </Box>
        </Stack>
    )
}

export default Profile;