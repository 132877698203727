import React from 'react';
import './App.css';
import LoginButton from './components/loginButton';
import LogoutButton from './components/logoutButton';
import Profile from './components/profile';
import { Alert, Box, Container, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useAuth0 } from '@auth0/auth0-react';
import { INFO_TOOLTIP } from './constants';

const App = () => {

  const { error } = useAuth0();

  return (
    <div className="App">
      <Stack spacing={2} marginTop={'2vh'}>
        <Box>
          <Typography variant="h4">Jarod's Apartment Guest Parking</Typography>
          <Typography variant='h6'>...but easier to use <Tooltip title={INFO_TOOLTIP} placement='right'><IconButton><InfoOutlinedIcon fontSize='inherit' /></IconButton></Tooltip></Typography>
        </Box>
        <Box>
          <LoginButton />
        </Box>
        <Box>
          <LogoutButton />
        </Box>
        <Box>
          <Container maxWidth='xs'>
            { error && <Alert severity='error'>{error.message}</Alert>}
          </Container>
        </Box>
        <Box>
          <Profile />
        </Box>
      </Stack>
    </div>
  );
}

export default App;
