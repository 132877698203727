import axios from "axios"

export const generateNewParkingRequest = async (location: string, policy: string, license: string, passcode: string, duration: number, email: string, phone: string): Promise<any> => {
    const payload = {
        viewpoint: new Date().toISOString(),
        location: location,
        policy: policy,
        vehicle: license,
        tenant: '422',
        token: passcode,
        space: 'Guest',
        duration: `PT${duration}H`,
        ...(email !== '' && { email: email }),
        ...(phone !== '' && { tel: phone })
    }

    return new Promise((resolve, reject) => {
        axios.post('https://api.parkingboss.com/v1/permits/temporary', new URLSearchParams(payload).toString()).then((res) => {
            if (res.status >= 200 && res.status < 300) {
                resolve(res.data)
            } else {
                reject(res)
            }
        }).catch((err) => {
            reject(err)
        })
    })
}

export interface ParkingPermit {
    id: string,
    from: Date,
    to: Date,
    url: string,
    expired: boolean
}

interface ParkingConstants {
    location: string,
    policy: string
}

export const getParkingConstants = async (): Promise<ParkingConstants> => {
    return new Promise((resolve, reject) => {
        axios.get('https://api.parkingboss.com/v1/locations/915glenwood/attendant')
            .then((res) => {
                const data = res.data;
                const location = String(data.locations?.item);
                const policy = String(Object.keys(data.policies?.items)[0])
                resolve({
                    location: location,
                    policy: policy
                })
            }).catch((err) => {
                reject(err)
            })
    })
}

export const cancelParking = async (permitId: string, notify: string) => {
    return axios.put(`https://api.parkingboss.com/v1/permits/${permitId}/expires`, new URLSearchParams({ permit: permitId, to: notify }))
}