import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

const LogoutButton = () => {
    const { logout, isAuthenticated, error } = useAuth0();

    if (!isAuthenticated && !error) return <div/>
    return (
        <Button onClick={() => logout({ returnTo: window.location.origin })}>Log Out</Button>
    )
}

export default LogoutButton;