import { Box, Button, CircularProgress, Grid, Slider, Typography } from "@mui/material";
import React from "react";

interface TimeSelectProps {
    reserve: Function,
    isSubmitting: boolean,
    disabled: boolean,
    time: number,
    setTime: Function
}

const TimeSelect = (props: TimeSelectProps) => {

    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item>
                <Box sx={{ width: 300 }}>
                    <Slider
                        min={1}
                        max={12}
                        step={1}
                        marks
                        valueLabelDisplay="auto"
                        value={props.time}
                        disabled={props.disabled}
                        onChange={(event, value) => props.setTime(Array.isArray(value) ? value[0] : value)}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Typography>{props.time} hour{props.time > 1 ? 's' : ''}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    { props.isSubmitting ?
                        <CircularProgress />
                        :
                        <Button disabled={props.disabled} variant="outlined" onClick={() => props.reserve()}>Reserve Parking</Button>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}

export default TimeSelect;