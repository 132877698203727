import { Button, CircularProgress, Grid, Stack, TextField } from "@mui/material";
import React from "react";

interface LicenseInputProps {
    value: string,
    updateValue: Function,
    saveLicense: Function,
    isSaving: boolean
}

const LicenseInput = (props: LicenseInputProps) => {
    
    return (
        <Grid container justifyContent="center">
            <Grid item>
                <Stack direction={"row"} spacing={2}>
                    <TextField id='license' label='License Plate' variant='outlined' value={props.value} onChange={(event) => props.updateValue(event.target.value)} />
                    {
                        (props.isSaving &&
                            <CircularProgress />
                        ) || <Button onClick={() => props.saveLicense()}>Save</Button>
                    }
                </Stack>
            </Grid>
        </Grid>
    )
}

export default LicenseInput;