import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

const LoginButton = () => {
    const { loginWithRedirect, isAuthenticated, error, isLoading } = useAuth0();

    if (isAuthenticated || error || isLoading) return <div/>
    return <Button variant="contained" onClick={() => loginWithRedirect()}>Log In</Button>
}

export default LoginButton;